import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Languages = (props) => {

  const language = props.language;
  const onLanguageClick = props.onLanguageClick;

  const pathArray = window.location.pathname.split('/');
  pathArray[1] = 'fr';
  const frPath = pathArray.join('/');
  pathArray[1] = 'en';
  const enPath = pathArray.join('/');

  return (
    <div className='languages'>
      <li className="language-first">
        <Link onClick={onLanguageClick} to={frPath} className={language === 'fr' ? 'activ-language' : ''} >FR</Link>
      </li>
      <li>
        <Link onClick={onLanguageClick} to={enPath} className={language === 'en' ? 'activ-language' : ''} >EN</Link>
      </li>
    </div>
  );
}

export default Languages;
