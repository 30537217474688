import React from 'react';
import { BrowserROuter as Router, Link} from 'react-router-dom';
import Languages from './Languages';
import Items from './Items';
import Burger from 'images/menu-01.svg';

import { HashLink as HLink } from 'react-router-hash-link';

const HomeNavbar = (props) => {

  const language = props.language

  const renderedItems = Items.map((item) => {

    const label = language === 'fr' ? item.label : item.label_en;

    let path = '';

    switch (item.label) {
      case 'Compétences':
        path = '#skills';
        return (
          <li key={item.label} >
            <a id='skills-btn' href={`/${language}${path}`}>{label}</a>
          </li>
        );
        break;
      case 'Présentation':
        path = '#presentation';
        return (
          <li key={item.label} >
            <a id='presentation-btn' href={`/${language}${path}`}>{label}</a>
          </li>
        );
        break;
      case 'Contact':
        path = '#contact';
        return (
          <li key={item.label} >
            <a id='contact-btn' href={`/${language}${path}`}>{label}</a>
          </li>
        );
        break;
      case "L'Equipe":
        path = '#team';
        return (
          <li key={item.label} >
            <a id='team-btn' href={`/${language}${path}`}>{label}</a>
          </li>
        );
        break;
      default:
        path = `/${item.path}`;
    }

    return (
      <li key={item.label} >
        <Link to={`/${language}${path}`}>{label}</Link>
      </li>
    );
  });

  const showMenu = (e) => {
    const dynaNavbar = document.querySelector('.dynamic-navbar');
    dynaNavbar.classList.add("show-navbar");
    setTimeout(() => document.getElementById('dyna-show').click(),300);
  }

  return (
    <div className='home-navbar'>
      <button onClick={showMenu} className='ws-show-menu-btn'><img src={Burger} alt="burger button"/></button>
      <ul className="website-menu-items">
        <div className="site-sections">
          {renderedItems}
        </div>
        <Languages language={language} onLanguageClick={props.onLanguageClick}/>
      </ul>
    </div>
  );
}

export default HomeNavbar;
