import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as HLink } from 'react-router-hash-link';
import Profile from 'images/profil.jpg';
import { motion, useAnimation } from "framer-motion";
import 'intersection-observer';
import { useInView } from "react-intersection-observer";
import ScrollableAnchor from 'react-scrollable-anchor';

const variants = {
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: 0.5 }
  })
};

const CreateAssociates = ({employee, index, language}) => {
  const justifyEnd = (index !== 0 && index % 2 !== 0) ? {'justifySelf': 'end'} : null;
  const src = employee.photo_key ? `https://res.cloudinary.com/mustachcloud/image/upload/${employee.photo_key}` : Profile

  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  return (
      <HLink to={`/${language}/team/${employee.id}#`} className='w-employee-card' style={justifyEnd}>
      <motion.div className='w-employee-card-motion' style={{'position': 'relative'}} ref={ref} animate={controls} variants={variants} initial={{ opacity: 0 }}>
        <div className="w-employee-img" style={{ backgroundImage: `url(${src})` }}></div>
        <div className='w-employee-name'>
          <h3>{`${employee.first_name} ${employee.last_name}`}</h3>
        </div>
        <div className="dark-hover"></div>
      </motion.div>
      </HLink>
  );
};

const CreateEmployees = ({employee, index, language}) => {
    const justifyEnd = (index !== 0 && index % 2 !== 0) ? {'justifySelf': 'end'} : null;
    const src = employee.photo_key ? `https://res.cloudinary.com/mustachcloud/image/upload/${employee.photo_key}` : Profile;

    const [ref, inView] = useInView();
    const controls = useAnimation();

    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [inView, controls]);

    return (
        <HLink to={`/${language}/team/${employee.id}#`} className='w-employee-card' style={justifyEnd}>
        <motion.div className='w-employee-card-motion' style={{'position': 'relative'}} ref={ref} animate={controls} variants={variants} initial={{ opacity: 0 }}>
          <div className="w-employee-img" style={{ backgroundImage: `url(${src})` }}></div>
          <div className='w-employee-name'>
            <h3>{`${employee.first_name} ${employee.last_name}`}</h3>
          </div>
          <div className="dark-hover"></div>
        </motion.div>
        </HLink>
    );
  };


const Team = ({ language, associates, collaborators }) => {

  const associatesTitle = language ==='fr' ? 'Les Associés' : 'Partners';
  const employeesTitle = language ==='fr' ? 'Les Collaborateurs' : 'Associates';

  const [height, setHeight] = useState(1000);

  useEffect(() => {
    const associatesHeight = document.querySelector('.associates').clientHeight;
    const associatesMarginBottom = 100;
    const collaboratorsHeight = document.querySelector('.collaborators').clientHeight;
    let paddingTop = 170;
    let paddingBottom = 170;
    if (window.innerWidth <= 1024) {
      paddingTop = 115;
      paddingBottom = 115;
    }
    let newHeight = associatesHeight + associatesMarginBottom + collaboratorsHeight + paddingTop + paddingBottom;
    newHeight = window.innerWidth <= 414 ? newHeight - 100 : newHeight;
    setHeight(newHeight);
  })

  const redenredAssociates = associates.map((employee, index) => {
    const justifyEnd = (index !== 0 && index % 2 !== 0) ? {'justifySelf': 'end'} : null;

    return (
        <CreateAssociates key={employee.id} employee={employee} index={index} language={language}/>
    );
  });

  const redenredEmployees = collaborators.map((employee, index) => {
    const justifyEnd = (index !== 0 && index % 2 !== 0) ? {'justifySelf': 'end'} : null;

    return (
      <CreateEmployees key={employee.id} employee={employee} index={index} language={language}/>
    );
  });


  return (
    <ScrollableAnchor id={'team'}>
      <div className="team-presentation" style={{'height': height}}>
        <div className="associates">
          <div className="associates-title">
            <h1 className='underlined-title'>{associatesTitle}</h1>
          </div>
          <div className="w-employees-cards">
            {redenredAssociates}
          </div>
        </div>
        <div className="collaborators">
          <div className="employees-title">
            <h1 className='underlined-title'>{employeesTitle}</h1>
          </div>
          <div className="w-employees-cards">
            {redenredEmployees}
          </div>
        </div>
      </div>
    </ScrollableAnchor>
  );
};

export default Team;
