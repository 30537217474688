import React from 'react';

const Footer = ({ language }) => {

  const mention = language === 'fr' ? 'Mentions Légales' : 'Terms of use';

  return (
    <div className="footer-section">
      <a href={`/${language}/mentions`}>{mention}</a>
      <a className='linkedin-logo' href="https://www.linkedin.com/company/arma-avocats/" target='_blank'><i className="fab fa-linkedin-in"></i></a>
    </div>
  );
};

export default Footer;
