import React, {useState} from 'react';
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';

import WebsiteDisplay from '../website-components/WebsiteDisplay';

document.addEventListener('DOMContentLoaded', () => {
  const App = () => {

    return (
      <Router>
        <WebsiteDisplay />
      </Router>
    );
  };

  const rootElement = document.body.appendChild(document.createElement('div'));
  if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
  } else {
    render(<App />, rootElement);
  }

})

