import React, { useState, useEffect } from 'react';
import ShowAside from './home-components/ShowAside';
import ScrollableAnchor from 'react-scrollable-anchor';

const Skills = ({ language, skills }) => {

  const title = language === 'fr' ? 'Compétences' : 'Our Expertises';

  const skillsItems = skills.map((skill) => {

    const title = language === 'fr' ? skill.title : skill.title_en;

    const content = skill.description;

    let paragraph = '';
    let initList = '';

    if (content.startsWith('<p>')) {
      paragraph = content.split('</p>')[0].split('<p>')[1];
      initList = content.split('</p>').slice(1).join('');
    } else {
      initList = content;
    }

    const list = initList ? initList.split('<li').join("<li class='arrow'") : initList;

    const contentEn = skill.description_en;
    const titleEn = skill.title_en;

    let paragraphEn = '';
    let initListEn = '';

    if (contentEn.startsWith('<p>')) {
      paragraphEn = contentEn.split('</p>')[0].split('<p>')[1];
      initListEn = contentEn.split('</p>').slice(1).join('');
    } else {
      initListEn = contentEn;
    }

    const listEn = initListEn ? initListEn.split('<li').join("<li class='arrow'") : initListEn;

    return (
    {
      id: skill.id,
      title: title,
      content: content,
      contentEn: contentEn,
      paragraph: `<p>${paragraph}</p>`,
      list: list,
      contentEn: contentEn,
      paragraphEn: `<p>${paragraphEn}</p>`,
      listEn: listEn,
      photo_key: skill.photo_key
    }
    );
  });

  useEffect(() => {

  }, [language])

  return (
    <ScrollableAnchor id={'skills'}>
      <div className="skills-section">
        <div className="skills-title">
          <h1 className='underlined-title'>{title}</h1>
        </div>
        {skills.length !== 0 &&
          <ShowAside items={skillsItems} language={language}/>
        }
      </div>
    </ScrollableAnchor>
  );
};

export default Skills;
