import React, { useState, useEffect } from 'react';
import ApiCall from './api/ApiCall';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import DynamicNavbar from './navbar/DynamicNavbar';

const Mention = ({ language, onLanguageClick }) => {

  const scrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [disclaimer, setDisclaimer] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getDisclaimer = async () => {
      const { data } = await ApiCall('/api/v1/disclaimers/');
      setDisclaimer(data);
      setLoaded(true);
    };

    getDisclaimer();

  }, []);

  const title = language ==='fr' ? 'Mentions Légales' : 'Legal Disclaimer';

  let content = '';
  if (loaded) {
    content = language ==='fr' ? disclaimer.content : disclaimer.content_en;
  }

  let lastMention = <div></div>;
  if (language === 'fr') {
    lastMention = <p className='last-sentence'>Ce site a été conçu par <a href="http://studio-mecquenem.fr/" target='_blank'>Constance de Mecquenem</a> (design) et <a href="https://www.mustdev.fr/" target='_blank'>Charles Delannoy</a> (intégration et développement).</p>
  } else {
    lastMention = <p className='last-sentence'>This website was designed by <a href="http://studio-mecquenem.fr/" target='_blank'>Constance de Mecquenem</a> and <a href="https://www.mustdev.fr/" target='_blank'>Charles Delannoy</a> (integration and development).</p>
  }

  return (
    <div className='mentions-view'>
      <Navbar language={language} onLanguageClick={onLanguageClick}/>
      <DynamicNavbar language={language} onLanguageClick={onLanguageClick}/>
      <div className="mention-container">
        <div className="mentions-card">
          <h1 className='underline'>{title}</h1>
          {loaded && <div dangerouslySetInnerHTML={{ __html: content }}/>}
          {lastMention}
        </div>
      </div>
      <Footer language={language}/>
      <a onClick={scrollTop} className='global-top-btn' href="#" ><i className="fas fa-chevron-up"></i></a>
    </div>
  );
};

export default Mention;
