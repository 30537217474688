import React, { Fragment } from 'react';
import HomeNavbar from '../navbar/HomeNavbar'
import Logo from 'images/logo-Arma.svg';
import { motion } from "framer-motion";

const HomeHeader = (props) => {

  const width = window.innerWidth;

  const variants = {
    visible: () => ({
      opacity: 1,
      transform: 'scale(1)',
      transition: { delay: 1, duration: 0.8 }
    })
  };

  const language = props.language
  const onLanguageClick = props.onLanguageClick

  return (
    <div className="home-header" >
      <HomeNavbar language={language} onLanguageClick={onLanguageClick}/>
      <motion.img className="home-logo" src={Logo} alt="arma logo" animate="visible" variants={variants} initial={{ opacity: 0, transform: 'scale(0)' }}/>

    </div>
  );
}

export default HomeHeader;
