import React, { useState, useEffect } from 'react';
import 'intersection-observer';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const variants = {
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: 0.5 * custom + 0.5 }
  })
};

const CreateSteps = ({ step, index, language }) => {

  const title = language === 'fr' ? step.title : step.title_en;
  const description = language === 'fr' ? step.description : step.description_en;

  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  const timer = window.innerWidth <= 414 ? 1 : index;

  return (
    <motion.div className='w-employee-card-motion' custom={timer} ref={ref} animate={controls} variants={variants} initial={{ opacity: 0 }}>
      <div key={step.id} className="step-card">
        <div className="step-title">
          <h3>{title}</h3>
        </div>
        <div className='step-description'>
          <p>{description}</p>
        </div>
      </div>
    </motion.div>
  );
};

const Approche = ({ language, steps }) => {

  const mainTitle = language === 'fr' ? 'Notre Approche' : 'Our Approach'
  const [padding, setPadding] = useState(400);
  const [height, setHeight] = useState(400);

  useEffect(() => {
      const topPosition = 100;
      const marginBottom = 60;
      const labelHeight = document.getElementById('labels').clientHeight;
      let newPadding = labelHeight + marginBottom + 173;
      newPadding = window.innerWidth <= 1024 ? newPadding - 100 : newPadding;
      newPadding = window.innerWidth <= 414 ? 250 : newPadding;
      setPadding(newPadding);
      const contentHeight = document.getElementById('approach-content').clientHeight;
      const newHeight = contentHeight + newPadding;
      setHeight(newHeight);
    });

  const renderedSteps = steps.map((step, index) => {

    return (
      <CreateSteps key={step.id} step={step} index={index} language={language} />
    );
  });

  return (
    <div className='approach' style={{'paddingTop': padding, 'height': height}}>
      <div id='approach-content' className="approach-content">
        <div className="step-section-title">
          <h1 className='underlined-title'>{mainTitle}</h1>
        </div>
        <div className="steps-cards">
          {renderedSteps}
        </div>
      </div>
    </div>
  );
};

export default Approche;
