import React, { useEffect } from 'react';
import CustomMap from './home-components/CustomMap';
import mailPicto from 'images/pictos-01.svg';
import phonePicto from 'images/pictos-02.svg';
import addressPicto from 'images/pictos-03.svg';
import ScrollableAnchor from 'react-scrollable-anchor';
import 'intersection-observer';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Contact = ({ language, cabinet, articles }) => {

  let phoneNumber = cabinet.telephone;
  let phoneLink = cabinet.telephone;
  if (cabinet.telephone) {
    phoneNumber = cabinet.telephone.match(/.{1,2}/g).join(' ');
    phoneLink = cabinet.telephone.substring(1, 10);
  }

  const scrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const variants = {
    visible: (custom) => ({
      opacity: 1,
      transition: { delay: 0.5 * custom + 0.5 }
    })
  };

  let sectionStyle = null;
  if (articles.length <= 1 && window.innerWidth <= 414) {
    sectionStyle = {paddingTop: '625px', height: '1440px'};
  }

  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  return (
    <div className="contact-section" style={sectionStyle}>
      <div className="contact-title">
        <ScrollableAnchor id={'contact'}>
          <h1 className='underlined-title'>Contact</h1>
        </ScrollableAnchor>
      </div>
      <div className='contact-elements'>
        <motion.div custom={0} ref={ref} animate={controls} variants={variants} initial={{ opacity: 0 }}>
        <div className="cab-mail">
          <a href={`mailto:${cabinet.mail}`}>
            <img id="mail-picto" src={mailPicto} alt="mail-picto"/>
            <h6>{cabinet.mail}</h6>
          </a>
        </div>
        </motion.div>
        <motion.div custom={1} ref={ref} animate={controls} variants={variants} initial={{ opacity: 0 }}>
        <div className="cab-phone">
          <a href={`tel:+33${phoneLink}`}>
            <img id="phone-picto" src={phonePicto} alt="phone-picto"/>
            <h6>{phoneNumber}</h6>
          </a>
        </div>
        </motion.div>
        <motion.div custom={2} ref={ref} animate={controls} variants={variants} initial={{ opacity: 0 }}>
        <div className="cab-address">
          <a href={`http://maps.google.com/maps?q=${cabinet.address}`} target="_blank">
            <img id="address-picto" src={addressPicto} alt="address-picto"/>
            <h6>{cabinet.address}</h6>
          </a>
        </div>
        </motion.div>
      </div>
      <CustomMap cabinet={cabinet}/>
      <a onClick={scrollTop} className='top-btn'><i className="fas fa-chevron-up"></i></a>
    </div>
  );
};

export default Contact;
