import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from "react-transition-group";

const Carroussel = ({items}) => {

  const [page, setPage] = useState(0);
  const [pagePosition, setPagePosition] = useState(1);
  const [pageAdditionner, setPageAdditionner] = useState(0);
  const nPages = Math.round(items.length / 2);

  const pagesButtonArray = [];
  for(let i=0; i < nPages; i++){
    pagesButtonArray.push(i + pageAdditionner);
  }

  const removeActivClass = () => {
    const selectActiv = document.querySelector('.activ-page-btn');
    if (selectActiv) {
      selectActiv.classList.remove('activ-page-btn');
    }
  }

  const onPageClick = (e) => {
    e.preventDefault();
    if (page !== parseInt(e.currentTarget.getAttribute('num'), 10)) {
      removeActivClass();
      const num = parseInt(e.currentTarget.getAttribute('num'), 10);
      if (num > page) {
        setPage(num - 2);
        slider.classList.add('next');
        setTimeout(function() {slider.classList.remove('next')}, 300);
        const pos = (num/2) + 1 - pageAdditionner;
        setPagePosition(pos);
      } else {
        setPage(num + 2);
        slider.classList.add('prev');
        setTimeout(function() {slider.classList.remove('prev')}, 300);
        const pos = (num/2) + 1 - pageAdditionner;
        setPagePosition(pos);
      }
      setPage(num);
      e.currentTarget.classList.add('activ-page-btn');
    }
  }

  let pagesNumber = 0;

  const pagesButtons = pagesButtonArray.map((n) => {
    pagesNumber++;
    while (pagesNumber <= 5 && n < nPages) {
      const activClass = n === (pagePosition - 1) ? 'activ-page-btn' : '';
      return (
        <button key={n} id={n * 2} className={`pages-btn ${activClass}`} onClick={onPageClick} num={n * 2} ><p>{n + 1}</p></button>
      );
    }
  })

  const slider = document.querySelector('.transition-container')

  const previousPage = (e) => {
    e.preventDefault();
    const newPage = page - 2
    if (newPage >= 0) {
      slider.classList.add('prev')
      setTimeout(function() {
        removeActivClass();
        setPage(newPage);
        const newPagePosition = pagePosition - 1;
        setPagePosition(newPagePosition);
        if (newPagePosition <= 0) {
          setPagePosition(5);
          const newAdditionner = pageAdditionner - 5;
          setPageAdditionner(newAdditionner);
        }
        if (document.getElementById(`${newPage}`)) {
          document.getElementById(`${newPage}`).classList.add('activ-page-btn');
        }
        slider.classList.remove('prev');
      }, 300)
    }
  }

  const nextPage = (e) => {
    e.preventDefault();
    const newPage = page + 2
    if (newPage <= ((nPages - 1) * 2)) {
      slider.classList.add('next');
      setTimeout(function() {
        removeActivClass();
        setPage(newPage);
        const newPagePosition = pagePosition + 1;
        setPagePosition(newPagePosition);
        if (newPagePosition > 5) {
          setPagePosition(1);
          const newAdditionner = pageAdditionner + 5;
          setPageAdditionner(newAdditionner);
        }
        if (document.getElementById(`${newPage}`)) {
          document.getElementById(`${newPage}`).classList.add('activ-page-btn');
        }
        slider.classList.remove('next');
      }, 300)
    }
  }

  let noGrid = null;
  if (items.length === 1){
    noGrid = {display: 'block'}
  }

  return (
    <div className='custom-carroussel'>

      <div className='transition-container'>
        <div className="caroussel-elements">
            {items[page - 2]}
            {items[page - 1]}
        </div>
        <div className="caroussel-elements" style={noGrid}>
            {items[page]}
            {items[page + 1]}
        </div>
        <div className="caroussel-elements">
            {items[page + 2]}
            {items[page + 3]}
        </div>

      </div>

      <div className="pages-menu">
        {pagePosition === 1 && pageAdditionner === 0 ? <button className='first-last-page'><i className="fas fa-chevron-left"></i></button> : <button onClick={previousPage}><i className="fas fa-chevron-left"></i></button>}
        <div className="pages-buttons">
          {pagesButtons}
        </div>
        {(pagePosition + pageAdditionner) === nPages ? <button className='first-last-page'><i className="fas fa-chevron-right"></i></button> : <button onClick={nextPage}><i className="fas fa-chevron-right"></i></button>}
      </div>
    </div>
  );
};

export default Carroussel;
