import React, { useState, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Home from './Home';
import Actuality from './Actuality';
import Article from './Article';
import Employee from './Employee';
import Mention from './Mention';

const WebsiteDisplay = () => {

  if (`${window.location.origin}/` === window.location.href ) {
      window.location.href = `${window.location.href}fr`;
    } else if (!(window.location.href.split('/')[3] === 'fr' ||
                 window.location.href.split('/')[3] === 'en' ||
                 window.location.href.split('/')[3] === 'en#skills' ||
                 window.location.href.split('/')[3] === 'fr#skills' ||
                 window.location.href.split('/')[3] === 'en#presentation' ||
                 window.location.href.split('/')[3] === 'fr#presentation' ||
                 window.location.href.split('/')[3] === 'fr#contact' ||
                 window.location.href.split('/')[3] === 'en#contact' ||
                 window.location.href.split('/')[3] === 'en#team' ||
                 window.location.href.split('/')[3] === 'fr#team' ||
                 window.location.href.split('/')[3] === 'fr#top' ||
                 window.location.href.split('/')[3] === 'en#top' ||
                 window.location.href.split('/')[3] === 'en#skills-btn' ||
                 window.location.href.split('/')[3] === 'fr#skills-btn' ||
                 window.location.href.split('/')[3] === 'en#presentation-btn' ||
                 window.location.href.split('/')[3] === 'fr#presentation-btn' ||
                 window.location.href.split('/')[3] === 'fr#contact-btn' ||
                 window.location.href.split('/')[3] === 'en#contact-btn' ||
                 window.location.href.split('/')[3] === 'en#team-btn' ||
                 window.location.href.split('/')[3] === 'fr#team-btn')) {
      const pathArray = window.location.href.split('/');
      pathArray[3] = 'fr';
      window.location.href = pathArray.join('/');
    }

    const [language, setLanguage] = useState(window.location.pathname.split('/')[1]);

    const onLanguageClick = (e) => {
      // e.preventDefault();
      document.querySelector('.activ-language').classList.remove('activ-language');
      const newLanguage = e.target.innerText.toLowerCase();
      setLanguage(newLanguage);
      e.target.classList.add('activ-language');
    }

  return (
    <Switch>
      <Route exact path="/:language" render={(props) => <Home {...props} language={language} onLanguageClick={onLanguageClick} />} />
      <Route exact path="/:language/actuality" render={(props) => <Actuality {...props} language={language} onLanguageClick={onLanguageClick} />} />
      <Route exact path="/:language/actuality/:id" render={(props) => <Article {...props} language={language} onLanguageClick={onLanguageClick} />} />
      <Route exact path="/:language/team/:id" render={(props) => <Employee {...props} language={language} onLanguageClick={onLanguageClick} />} />
      <Route exact path="/:language/mentions" render={(props) => <Mention {...props} language={language} onLanguageClick={onLanguageClick} />} />
    </Switch>
  );
}

export default withRouter(WebsiteDisplay);
