import React, { useState } from 'react';
import { BrowserROuter as Router, Link} from 'react-router-dom';
import Languages from './Languages';
import Items from './Items'
import { HashLink as HLink } from 'react-router-hash-link';
import Burger from 'images/menu-01.svg';
import Cross from 'images/menu-02.svg';

const Navbar = ({ language, onLanguageClick }) => {

  const [btnSrc, setBtnSrc] = useState(Burger);

  const renderedItems = Items.map((item) => {

    const label = language === 'fr' ? item.label : item.label_en;

    let path = '';

    switch (item.label) {
      case 'Compétences':
        path = '#skills-btn';
        return (
          <li key={item.label} >
            <a href={`/${language}${path}`}>{label}</a>
          </li>
        );
        break;
      case 'Présentation':
        path = '#presentation-btn';
        return (
          <li key={item.label} >
            <a href={`/${language}${path}`}>{label}</a>
          </li>
        );
        break;
      case 'Contact':
        path = '#contact-btn';
        return (
          <li key={item.label} >
            <a href={`/${language}${path}`}>{label}</a>
          </li>
        );
        break;
      case "L'Equipe":
        path = '#team-btn';
        return (
          <li key={item.label} >
            <a href={`/${language}${path}`}>{label}</a>
          </li>
        );
        break;
      default:
        path = `/${item.path}`;
    }

    const activClass = window.location.pathname.split('/')[2] === item.path ? 'activ-item' : '';

    return (
      <li key={item.label} className={activClass}>
        <Link to={`/${language}${path}`}>{label}</Link>
      </li>
    );
  });

  const showMenu = (e) => {
    const btn = document.querySelector('.ws-show-menu-btn i');
    const menu = document.getElementById('main-menu');
    setTimeout(function() {
      const newSrc = btnSrc === Burger ? Cross : Burger;
      setBtnSrc(newSrc);
    }, 500);
    menu.classList.toggle('show-phone-menu-items');
  }

  const mentionsTitle = language === 'fr' ? 'Mentions Légales' : 'Legal Disclaimer';

  return (
    <div className='website-navbar'>
      <Link to={`/${language}`}><div className="navbar-logo"></div></Link>
      <button onClick={showMenu} className='ws-show-menu-btn'><img src={btnSrc} alt="burger button"/></button>
      <ul className="website-menu-items" id="main-menu" >
        <div className="site-sections">
          {renderedItems}
        </div>
        <Languages language={language} onLanguageClick={onLanguageClick}/>
        <div className="phone-footer-menu">
          <li><a href={`/${language}/mentions`}>{mentionsTitle}</a></li>
          <li><a href='https://www.linkedin.com/company/arma-avocats/' target='_blank'><i className="fab fa-linkedin-in"></i></a></li>
        </div>
      </ul>
    </div>
  );
}

export default Navbar;
