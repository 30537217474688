import React from 'react';
import { Link} from 'react-router-dom';
import { HashLink as HLink } from 'react-router-hash-link';
import Carroussel from './home-components/Carroussel';
import Arrow from 'images/fleche-article.svg';

const Actuality = ({ language, cabinet, articles }) => {

  let src = null;
  if (cabinet.photo_key) {
    src = `https://res.cloudinary.com/mustachcloud/image/upload/${cabinet.photo_key}`;
  }

  const title = language === 'fr' ? 'Actualités' : 'News'
  const linkLabel = language === 'fr' ? 'VOIR TOUTES LES ACTUALITÉS ' : 'SEE ALL NEWS'

  let articleStyle = null;
  let containerStyle = null;
  if (articles.length <= 1) {
    articleStyle = {margin: '0 auto'};
    containerStyle = window.innerWidth <= 414 ? {height: '535px'} : null ;
  }


  const renderedArticles = articles.map((article, index) => {

    const subject = article.subject ? article.subject : null;
    let subjectTitle = '';
    if (subject) {
      subjectTitle = language === 'fr' ? subject.title : subject.title_en;
    }

    const articleContent = language === 'fr' ? article.content : article.content_en;
    const content = `${articleContent.substr(0, 200)}...`;
    const articleTitle = language === 'fr' ? article.title : article.title_en;
    const title = articleTitle.length > 50 ? `${articleTitle.substr(0, 50)}...` : articleTitle;

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateLanguage = language === 'fr' ? 'fr-FR' : 'en-EN';
    const dateArray = article.date.split('-');
    const dateFormat = (new Date(Date.UTC(dateArray[0],dateArray[1] - 1, dateArray[2]))).toLocaleDateString(dateLanguage, options);
    const datePrefix = language === 'fr' ? 'Le ' : '';

    const linkTitle = language === 'fr' ? 'LIRE L\' ARTICLE' : 'READ THE ARTICLE'

    const justifyEnd = (index !== 0 && index % 2 !== 0) ? {'justifySelf': 'end'} : null;

    return (
      <div className='w-article-card' style={justifyEnd, articleStyle}>
        <div className="card-title">
          <div className="w-article-subject">{subjectTitle.toUpperCase()}</div>
          <div className="w-title-underline"></div>
        </div>
        <div className="w-article-infos">
          <h5>{title}</h5>
          <p className="w-article-date">{datePrefix}{dateFormat}</p>
        </div>
        <div className="article-link">
          <HLink className="article-card-link" to={`/${language}/actuality/${article.id}#`}>{linkTitle}<img src={Arrow} alt="arrow"/></HLink>
        </div>
      </div>
    );
  })

  const noArticleText = language === 'fr' ? "Il n'y a pas d'actualités pour le moment." : "There is no news yet."

  let articlesSection = <Carroussel items={renderedArticles}/>;
  if (articles.length === 0) {
    articlesSection = <div className="no-articles"><h3>{noArticleText}</h3></div>
  }

  return (
    <div className="actuality-section" style={{'backgroundImage': `url(${src})`}}>
      <div className="articles-cards-container" style={containerStyle}>
        <div className="actuality-title">
          <h1 className='underlined-title'>{title}</h1>
        </div>
        {articlesSection}
        <div className="actuality-link">
          <HLink className='all-news-link' to={`${language}/actuality#`}>{linkLabel}</HLink>
        </div>
      </div>
    </div>
  );
};

export default Actuality;
