const Items = [
    {
      label: "Présentation",
      label_en: 'Our Office',
      path: ""
    },
    {
      label: "L'Equipe",
      label_en: 'Team',
      path: "team"
    },
    {
      label: "Compétences",
      label_en: 'Our Expertises',
      path: "skills"
    },
    {
      label: "Actualités",
      label_en: 'News',
      path: "actuality"
    },
    {
      label: "Contact",
      label_en: 'Contact',
      path: "contact"
    },
  ];

  export default Items;
