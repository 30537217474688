import React, { useState, useEffect, Fragment } from 'react';
import ApiCall from './api/ApiCall';
import HomeHeader from './homepage/HomeHeader';
import Cabinet from './homepage/Cabinet';
import Approche from './homepage/Approche';
import Team from './homepage/Team';
import Skills from './homepage/Skills';
import Actuality from './homepage/Actuality';
import Contact from './homepage/Contact';
import Footer from './footer/Footer';
import DynamicNavbar from './navbar/DynamicNavbar';

const Home = (props) => {

  const goToAnchor = (anchor) => {
    if (document.querySelector(`#${anchor}`)) {
      document.querySelector(`#${anchor}`).click();
    } else {
      // window.location.href = `${window.location.origin}${window.location.pathname}`;
      setTimeout(function(){document.querySelector(`#${anchor}-btn`).click()}, 10);
    }
  }

  useEffect(() => {
    const anchorPath = window.location.href.split('#');

    if (anchorPath[1] && anchorPath[1] !== 'top') {
      if (anchorPath[1].split('-')[1]) {
        setTimeout(function(){goToAnchor(anchorPath[1])}, 500);
      } else {
        setTimeout(function(){document.querySelector(`#${anchorPath[1]}-btn`).click()}, 800);
      }
    }

  }, [])

  const language = props.language
  const [cabinet, setCabinet] = useState({});
  const [labels, setLabels] = useState([]);
  const [steps, setSteps] = useState([]);
  const [associates, setAssociates] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [skills, setSkills] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const byId = (a, b) => {
    return a.id > b.id ? 1 : -1;
  }

  const byDate = (a, b) => {
    return a.date < b.date ? 1 : -1;
  }

  const byTitle = (a, b) => {
    return a.title < b.title ? -1 : 1;
  }

  useEffect(() => {
    const getCabinet = async () => {
      const { data } = await ApiCall('/api/v1/cabinets/');
      setCabinet(data);
    };

    const getLabels = async () => {
      const { data } = await ApiCall('/api/v1/labels/');
      setLabels(data.sort(byTitle));
    };

    const getSteps = async () => {
      const { data } = await ApiCall('/api/v1/steps/');
      setSteps(data.sort(byId));
    };

    const getEmployees = async () => {
      const { data } = await ApiCall('/api/v1/employees/');
      const allEmployees = data.sort(byId);
      setAssociates(allEmployees.filter((employee) => employee.role === 'Associé' && employee.published).sort(byId));
      setCollaborators(allEmployees.filter((employee) => employee.role === 'Collaborateur' && employee.published).sort(byId));
    };

    const getSkills = async () => {
      const { data } = await ApiCall('/api/v1/skills/');
      setSkills(data.sort(byId));
    };

    const getArticles = async () => {
      const { data } = await ApiCall('/api/v1/articles/');
      const allArticles = data.sort(byDate);
      setArticles(allArticles.filter((article) => article.published).sort(byDate));
    };

    getLabels();
    getCabinet();
    getSteps();
    getEmployees();
    getSkills();
    getArticles();
    setLoaded(true);

  }, [])

  return (
    <Fragment>
      <HomeHeader language={language} onLanguageClick={props.onLanguageClick}/>
      <DynamicNavbar language={language} onLanguageClick={props.onLanguageClick}/>
      {loaded &&
        <Fragment>
          <Cabinet language={language} cabinet={cabinet} labels={labels} />
          <Approche language={language} steps={steps} />
          <Team language={language} associates={associates} collaborators={collaborators} />
          <Skills language={language} skills={skills} />
          <Actuality language={language} cabinet={cabinet} articles={articles} />
          <Contact language={language} cabinet={cabinet} articles={articles}/>
        </Fragment>
      }
      <Footer language={language} />
    </Fragment>
  );
};

export default Home;
