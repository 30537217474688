import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ApiCall from './api/ApiCall';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import DynamicNavbar from './navbar/DynamicNavbar';

const Article = ({ language, onLanguageClick, match} ) => {

  const byDate = (a, b) => {
    return a.date < b.date ? 1 : -1;
  };

  const [id, setId] = useState(match.params.id)
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState({});
  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getArticles = async () => {
      const { data } = await ApiCall('/api/v1/articles/');
      const allArticles = data.sort(byDate);
      const publishedArticles = allArticles.filter((article) => article.published).sort(byDate);
      setArticles(publishedArticles);
      const actualArticle = publishedArticles.find((article) => article.id === parseInt(id, 10));
      const index = publishedArticles.indexOf(actualArticle);
      const nId = index >= (publishedArticles.length - 1) ? null : publishedArticles[index + 1].id;
      const pId = index <= 0 ? null : publishedArticles[index - 1].id;
      setPrevId(pId);
      setNextId(nId);
      setArticle(actualArticle);
      setLoaded(true);
    };

    getArticles();
  }, [id])

  const renderArticle = () => {

    const subject = language === 'fr' ? article.subject.title : article.subject.title_en;
    const title = language === 'fr' ? article.title : article.title_en;
    const content = language === 'fr' ? article.content : article.content_en;

    const prev = language === 'fr' ? 'précédent' : 'previous';
    const previousLink = prevId ? <Link onClick={() => setId(prevId)} to={`/${language}/actuality/${prevId}`}><i className="fas fa-chevron-left"></i> {prev.toUpperCase()}</Link> : <div></div>;

    const next = language === 'fr' ? 'suivant' : 'next';
    const nextLink = nextId ? <Link onClick={() => setId(nextId)} to={`/${language}/actuality/${nextId}`}>{next.toUpperCase()} <i className="fas fa-chevron-right"></i></Link> : <div></div>;

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateLanguage = language === 'fr' ? 'fr-FR' : 'en-EN';
    const dateArray = article.date.split('-');
    const dateFormat = (new Date(Date.UTC(dateArray[0],dateArray[1] - 1, dateArray[2]))).toLocaleDateString(dateLanguage, options);

    return (
      <Fragment>
        <div className="head-article">
          <div className="subject-date">
            <h6>{subject.toUpperCase()}</h6>
            <h6>{dateFormat.toUpperCase()}</h6>
          </div>
          <div className="article-title">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="content-card">
          <div className='article-content' dangerouslySetInnerHTML={{ __html: content }}/>
          <div className="article-navigation">
            {previousLink}
            {nextLink}
          </div>
        </div>
      </Fragment>
    );
  };

  const renderNoArticle = () => {
    if (loaded) {
      const text = language === 'fr' ? "Cette page n'existe pas." : 'This page does not exist.';
      const btnText = language === 'fr' ? "Retour à l'acceuil" : 'Back to home page'
      return (
        <div className='error-page'>
          <div className="error-message">
            <h1>{text}</h1>
            <a href={`/${language}`}>{btnText}</a>
          </div>
        </div>
      );
    } else {
      return <div>Loading...</div>
    }
  }

  const scrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className='article-view'>
      <Navbar language={language} onLanguageClick={onLanguageClick}/>
      <DynamicNavbar language={language} onLanguageClick={onLanguageClick}/>
      <div className="article-container">
        {loaded &&
          article ? renderArticle() : renderNoArticle()
        }
      </div>
      <Footer language={language}/>
      <a onClick={scrollTop} className='global-top-btn' href="#" ><i className="fas fa-chevron-up"></i></a>
    </div>
  );
};

export default Article;
