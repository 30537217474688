import React, { useState, useEffect, Fragment } from 'react';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

const Cabinet = ({ language, cabinet, labels }) => {

  const title = language === 'fr' ? 'Le Cabinet' : 'Our Office';
  const descr = language === 'fr' ? cabinet.description : cabinet.description_en

  const [height, setHeight] = useState(430);
  configureAnchors({offset: -60, scrollDuration: 800});

  useEffect(() => {
    const topPosition = 100;
    const marginBottom = 60;
    const labelHeight = document.getElementById('labels').clientHeight;
    const divHeight = document.getElementById('cabinet-card').clientHeight;
    const newHeight = divHeight + topPosition - labelHeight - marginBottom + 5;
    setHeight(newHeight);
  })

  const renderedLabels = labels.map((label) => {

    const labelTitle = label.title.toUpperCase().split('(');

    if (label.photo_key) {
      return (
        <div key={label.id} className="label-card">
          <img src={`https://res.cloudinary.com/mustachcloud/image/upload/${label.photo_key}`}/>
        </div>
      );
    }
    return (
      <div key={label.id} className="label-card">
        <h4>{labelTitle[0]}</h4>
        {labelTitle[1] ? <p>({labelTitle[1]}</p> : null}
      </div>
    );
  });

  let src = null;
  if (cabinet.photo_key) {
    src = `https://res.cloudinary.com/mustachcloud/image/upload/${cabinet.team_photo_key}`;
  }

  return (
    <div className='cabinet-presentation' style={{'height': height}}>
      <ScrollableAnchor id={'presentation'}>
        <div className="cabinet-card" id='cabinet-card'>
            <div className="cabinet-content">
              <div className="cabinet-title">
                <h1 className='underlined-title'>{title}</h1>
              </div>
              <div className="team-img">
                <img src={src} alt="team-picture"/>
              </div>
              <div className="cabinet-description">
                <div dangerouslySetInnerHTML={{ __html: descr }}/>
              </div>
            </div>
          <div className="cabinet-labels" id='labels' >
            {renderedLabels}
          </div>
        </div>
      </ScrollableAnchor>
    </div>
  );
};

export default Cabinet;
