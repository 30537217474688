import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import Marker from 'images/map-marker.svg';

const CustomMap = ({ cabinet }) => {

  const { MAPBOX_API_KEY } = process.env;
  const [loaded, setLoaded] = useState(false);

  const fitMapToMarkers = (map, markers) => {
    const bounds = new mapboxgl.LngLatBounds();
    markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
    map.fitBounds(bounds, { padding: 70, maxZoom: 14.5, duration: 0 });
  };

  const initMapbox = () => {
    const mapElement = document.getElementById('map');

    if (mapElement) {
      mapboxgl.accessToken = MAPBOX_API_KEY;
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/cofzenuts/cki82kfxp8yu319om65bbj0kj'
      });
  const markers = [{lng: cabinet.longitude, lat: cabinet.latitude, image_url: Marker}];
    markers.forEach((marker) => {

      let markerSize = '160px';
      if (window.innerWidth <= 1024) {
        markerSize = '160px';
      }

      const element = document.createElement('div');
      element.className = 'marker';
      element.style.backgroundImage = `url('${marker.image_url}')`;
      element.style.backgroundSize = '60px';
      element.style.backgroundRepeat = 'no-repeat';
      element.style.width = markerSize;
      element.style.height = markerSize;
      element.style.backgroundPosition = '50% top';

      new mapboxgl.Marker(element)
        .setLngLat([ marker.lng, marker.lat ])
        .addTo(map);
    });

    fitMapToMarkers(map, markers);

    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl(), 'top-left');

    setLoaded(true);
    }
  };

  useEffect(() => {
    if (!loaded && cabinet.longitude) {
      initMapbox();
    }
  })

  let mapHeight = '450px';
  if (window.innerWidth <= 1024) {
    mapHeight = '260px';
  }

  return(
    <div id='map'
         style={{'width': '100%', 'height': mapHeight}}
    />
  );
};

export default CustomMap;
