import React, { useState, useEffect } from 'react';
import Profile from 'images/profil.jpg';
import ApiCall from './api/ApiCall';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import DynamicNavbar from './navbar/DynamicNavbar';

const Employee = ({ language, onLanguageClick, match }) => {

  const [id, setId] = useState(match.params.id);
  const [employee, setEmployee] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getEmployee = async () => {
      const { data } = await ApiCall(`/api/v1/employees/`);
      const allEmployees = data;
      const publishedEmployees = allEmployees.filter((employee) => employee.published);
      const actualEmployee = publishedEmployees.find((employee) => employee.id === parseInt(id, 10));
      if (actualEmployee) {
        const { data } = await ApiCall(`/api/v1/employees/${id}`);
        setEmployee(data);
      }
      setLoaded(true);
    };

    getEmployee();

  }, []);


  const renderEmployee = () => {

    const renderedSection = loaded ? employee.sections.map((section) => {

      const sTitle = language ==='fr' ? section.title : section.title_en;

      const renderedItems = section.items.map((item) => {

        const iTitle = language ==='fr' ? item.title : item.title_en;

        return (
          <li key={item.id} className='arrow'>{iTitle}</li>
        );
      });

      if (section.items.length > 0) {
        return (
          <div key={section.id} className='employee-section'>
            <h1>{sTitle}</h1>
            <ul>
              {renderedItems}
            </ul>
          </div>
        );
      } else return null;
    }) : null;

    const src = employee.photo_key ? `https://res.cloudinary.com/mustachcloud/image/upload/${employee.photo_key}` : Profile;
    const lineWidth = employee.last_name.length * 5.5;
    let lineHeight = '1.3vw';
    if (window.innerWidth <= 414) {
      lineHeight = '3vw';
    }
    const bckSizeCss = `${lineWidth}% ${lineHeight}`
    const descr = language === 'fr' ? employee.description : employee.description_en
    let role = ''
    if (language === 'fr') {
      role = employee.role;
    } else {
      role = employee.role === 'Associé' ? 'Partner' : 'Associate';
    }

    return (
      <div className="presentation-card">
        <div className="presentation-head">
          <div className="presentation-img" style={{ backgroundImage: `url(${src})` }}></div>
          <div className="presentation-infos">
            <div className="presentation-name-role">
              <h1>{employee.first_name}</h1>
              <h1 className='underline' style={{'backgroundSize': bckSizeCss}}>{employee.last_name}</h1>
              <h5>{role}</h5>
            </div>
            <div className="presentation-contact-infos">
              <a className="employee-contact" href={`mailto:${employee.mail}`}><i className="fas fa-envelope"></i>{employee.mail}</a>
              <a className="employee-contact" href={`tel:+33${employee.telephone.substring(1, 10)}`} ><i className="fas fa-phone-alt"></i>{employee.telephone.match(/.{1,2}/g).join(' ')}</a>
              <a className="employee-contact" href={`https://${employee.linkedin}`} target="_blank"><i className="fab fa-linkedin-in"></i>Linkedin</a>
            </div>
          </div>
        </div>
        <div className="presentation-content">
          <div className='employee-description' dangerouslySetInnerHTML={{ __html: descr }}/>
          {renderedSection}
        </div>
      </div>
    );
  }

  const renderNoEmployee = () => {
    if (loaded) {
      const text = language === 'fr' ? "Cette page n'existe pas." : 'This page does not exist.';
      const btnText = language === 'fr' ? "Retour à l'acceuil" : 'Back to home page'
      return (
        <div className='error-page'>
          <div className="error-message">
            <h1>{text}</h1>
            <a href={`/${language}`}>{btnText}</a>
          </div>
        </div>
      );
    } else {
      return (
        <div>Loading...</div>
      );
    }
  };

  const scrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='employee-view'>
      <Navbar language={language} onLanguageClick={onLanguageClick}/>
      <DynamicNavbar language={language} onLanguageClick={onLanguageClick}/>
      <div className="employee-container">
        {loaded &&
          employee ? renderEmployee() : renderNoEmployee()
        }
      </div>
      <Footer language={language}/>
      <a onClick={scrollTop} className='global-top-btn' href="#" ><i className="fas fa-chevron-up"></i></a>
    </div>
  );
};

export default Employee;
