import React, { useState, useEffect } from 'react';

const ShowAside = ({ items, language }) => {

  const [activeItem, setActiveItem] = useState(items[0]);

  const onTitleClick = (item) => {
    setActiveItem(item);
  }

  const renderedItems = items.map((item) => {
    const activeClass = item.id === activeItem.id ? 'active' : '';

    return (
      <React.Fragment key={item.id}>
        <div
          className={`skill-title ${activeClass}`}
          onClick={() => onTitleClick(item)}
        >
          {item.title}
        </div>
      </React.Fragment>
      );
  });

  const getImage = () => {
    if (activeItem.photo_key) {
      return <img src={`https://res.cloudinary.com/mustachcloud/image/upload/${activeItem.photo_key}`} alt="skill-picture" />;
    }
  }

  const activParagraph = language === 'fr' ? activeItem.paragraph : activeItem.paragraphEn;
  const activList = language === 'fr' ? activeItem.list : activeItem.listEn;

  return (
    <div className="skills-show-aside">
      <div className="skills-titles" >
        {renderedItems}
      </div>
      <div className="skills-content">
        <div className="skills-content-main">
          {getImage()}
          <div dangerouslySetInnerHTML={{ __html: activParagraph }}/>
        </div>
        <div className="skills-content-list">
          <ul><div dangerouslySetInnerHTML={{ __html: activList }}/></ul>
        </div>
      </div>
    </div>
  );
};

export default ShowAside;
