import React, { useState } from 'react';
import { BrowserROuter as Router, Link} from 'react-router-dom';
import Languages from './Languages';
import Items from './Items'
import { HashLink as HLink } from 'react-router-hash-link';
import Burger from 'images/menu-01.svg';
import Cross from 'images/menu-02.svg';

const Navbar = (props) => {

  const language = props.language

  const [btnSrc, setBtnSrc] = useState(Burger);

  const renderedItems = Items.map((item) => {

    const label = language === 'fr' ? item.label : item.label_en;

    let path = '';

    const pathLength = window.location.href.split('/').length;

    if (pathLength > 4) {
      switch (item.label) {
        case 'Compétences':
          path = '#skills-btn';
          return (
            <li key={item.label} >
              <a href={`/${language}${path}`}>{label}</a>
            </li>
          );
          break;
        case 'Présentation':
          path = '#presentation-btn';
          return (
            <li key={item.label} >
              <a href={`/${language}${path}`}>{label}</a>
            </li>
          );
          break;
        case 'Contact':
          path = '#contact-btn';
          return (
            <li key={item.label} >
              <a href={`/${language}${path}`}>{label}</a>
            </li>
          );
          break;
        case "L'Equipe":
          path = '#team-btn';
          return (
            <li key={item.label} >
              <a href={`/${language}${path}`}>{label}</a>
            </li>
          );
          break;
        default:
          path = `/${item.path}`;
      }
    } else {
      switch (item.label) {
        case 'Compétences':
          path = '#skills';
          return (
            <li key={item.label} >
              <a id='skills-btn' href={`/${language}${path}`}>{label}</a>
            </li>
          );
          break;
        case 'Présentation':
          path = '#presentation';
          return (
            <li key={item.label} >
              <a id='presentation-btn' href={`/${language}${path}`}>{label}</a>
            </li>
          );
          break;
        case 'Contact':
          path = '#contact';
          return (
            <li key={item.label} >
              <a id='contact-btn' href={`/${language}${path}`}>{label}</a>
            </li>
          );
          break;
        case "L'Equipe":
          path = '#team';
          return (
            <li key={item.label} >
              <a id='team-btn' href={`/${language}${path}`}>{label}</a>
            </li>
          );
          break;
        default:
          path = `/${item.path}`;
      }
    }

    const activClass = window.location.pathname.split('/')[2] === item.path ? 'activ-item' : '';

    return (
      <li key={item.label} className={activClass}>
        <HLink to={`/${language}${path}#`}>{label}</HLink>
      </li>
    );
  });

  const dynaNavbar = document.querySelector('.dynamic-navbar');
  let lastScrollValue = document.documentElement.scrollTop;

  document.addEventListener('scroll',() => {
      let top  = document.documentElement.scrollTop;;
      if(lastScrollValue < top && dynaNavbar) {
        dynaNavbar.classList.remove("show-navbar");
        document.getElementById('dyna-menu').classList.remove('show-phone-menu-items');
        setTimeout(function() {setBtnSrc(Burger)}, 500);
      } else if (top < window.innerHeight && dynaNavbar) {
        dynaNavbar.classList.remove("show-navbar");
        document.getElementById('dyna-menu').classList.remove('show-phone-menu-items');
        setTimeout(function() {setBtnSrc(Burger)}, 500);
      } else if (dynaNavbar) {
        dynaNavbar.classList.add("show-navbar");
      }
      lastScrollValue = top;
  });

  const showMenu = (e) => {
    const menu = document.getElementById('dyna-menu');
    setTimeout(function() {
      const newSrc = btnSrc === Burger ? Cross : Burger;
      setBtnSrc(newSrc);
    }, 500);
    menu.classList.toggle('show-phone-menu-items');
    if (!document.querySelector('.show-phone-menu-items')) {
      setTimeout(() => dynaNavbar.classList.remove("show-navbar"),500);
    }
  }

  const scrollUpIfHome = (e) => {
    if (window.location.pathname.split('/').length === 2) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  let logoLink = <HLink to={`/${language}#`}><div className="navbar-logo"></div></HLink>;
  if (window.location.pathname.split('/').length === 2) {
    logoLink = <Link onClick={scrollUpIfHome} to={`/${language}`}><div className="navbar-logo"></div></Link>
  }

  const mentionsTitle = language === 'fr' ? 'Mentions Légales' : 'Legal Disclaimer';


  return (
    <div className='website-navbar dynamic-navbar'>
      {logoLink}
      <button onClick={showMenu} className='ws-show-menu-btn' id='dyna-show'><img src={btnSrc} alt="burger button"/></button>
      <ul className="website-menu-items" id="dyna-menu" >
        <div className="site-sections">
          {renderedItems}
        </div>
        <Languages language={language} onLanguageClick={props.onLanguageClick}/>
        <div className="phone-footer-menu">
          <li><a href={`/${language}/mentions`}>{mentionsTitle}</a></li>
          <li><a href='https://www.linkedin.com/company/arma-avocats/' target='_blank'><i className="fab fa-linkedin-in"></i></a></li>
        </div>
      </ul>
    </div>
  );
}

export default Navbar;
