import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ApiCall from './api/ApiCall';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import DynamicNavbar from './navbar/DynamicNavbar';
import 'intersection-observer';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Arrow from 'images/fleche-article.svg';

const variants = {
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: 0.5 }
  })
};

const CreateArticles = ({article, index, language, noGrid, centerCard}) => {

  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  let baseChars = 350;
  let titleMultiplier = 78;
  if (window.innerWidth <= 1024) {
    baseChars = 300;
    titleMultiplier = 60;
  }

  const articleTitle = language === 'fr' ? article.title : article.title_en;
  const titleLines = articleTitle.length > 95 ? 3 : Math.ceil(articleTitle.length / 20);
  const contentChars = baseChars - (titleLines * titleMultiplier);
  const title = articleTitle.length > 106 ? `${articleTitle.substr(0, 106)}...` : articleTitle;

  const articleContent = language === 'fr' ? article.content : article.content_en;
  // const firstParagraph = articleContent.split('</p>')[0].split('<p>')[1];
  const htmlObject = document.createElement('div');
  htmlObject.innerHTML = articleContent.split('</p>')[0] + '</p>';
  const firstParagraph = htmlObject.querySelector('p').innerText
  const content = firstParagraph.length < contentChars ? firstParagraph : `${firstParagraph.substr(0, contentChars)}...`;

  let subjectTitle = null;
  if (article.subject) {
    subjectTitle = language === 'fr' ? article.subject.title : article.subject.title_en;
  }
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const dateLanguage = language === 'fr' ? 'fr-FR' : 'en-EN';
  const dateArray = article.date.split('-');
  const dateFormat = (new Date(Date.UTC(dateArray[0],dateArray[1] - 1, dateArray[2]))).toLocaleDateString(dateLanguage, options);
  const datePrefix = language === 'fr' ? 'Le ' : '';
  const linkTitle = language === 'fr' ? 'LIRE L\' ARTICLE' : 'READ THE ARTICLE';

  const justifyEnd = (index !== 0 && index % 2 !== 0) ? {'justifySelf': 'end'} : null;

  return (
    <motion.div style={noGrid} ref={ref} animate={controls} variants={variants} initial={{ opacity: 0 }}>
      <div key={article.id} className='ap-article-card' style={justifyEnd, centerCard}>
        <div className="card-title">
          <div className="ap-article-subject">{subjectTitle.toUpperCase()}</div>
          <div className="ap-title-underline"></div>
        </div>
        <div className="ap-article-corps">
          <div className="ap-article-infos">
            <h5 id={`article-title-${article.id}`}> {title}</h5>
            <p className="ap-article-date">{datePrefix}{dateFormat}</p>
          </div>
          <div className="ap-article-content"><div className='ap-article-text' dangerouslySetInnerHTML={{ __html: content }}/></div>
        </div>
        <div className="ap-article-link">
          <Link className="ap-article-card-link" to={`/${language}/actuality/${article.id}`}>{linkTitle}<img src={Arrow} alt="arrow"/></Link>
        </div>
      </div>
    </motion.div>
  );
};

const Actuality = ({ language, onLanguageClick}) => {

  const byDate = (a, b) => {
    return a.date < b.date ? 1 : -1;
  }

  const [articles, setArticles] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getArticles = async () => {
      const { data } = await ApiCall('/api/v1/articles/');
      const allArticles = data.sort(byDate);
      setArticles(allArticles.filter((article) => article.published).sort(byDate));
    };

    getArticles();
    setLoaded(true);
  }, [])

  let centerCard = null;
  let noGrid = null;
  if (articles.length === 1) {
    centerCard = {margin: '0 auto'};
    noGrid = {gridColumn: '1 / 3'};
  }

  const renderedArticles = loaded ? articles.map((article, index) => {

  return (
    <CreateArticles key={article.id} article={article} index={index} language={language} noGrid={noGrid} centerCard={centerCard}/>
  );
  }) : null;

  const noArticleText = language === 'fr' ? "Il n'y a pas d'actualités pour le moment." : "There is no news yet."

  let articlesSection = renderedArticles;
  if (articles.length === 0) {
    articlesSection = <div className="no-articles"><h3>{noArticleText}</h3></div>
  }

  const scrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className="actuality-view">
      <Navbar language={language} onLanguageClick={onLanguageClick}/>
      <DynamicNavbar language={language} onLanguageClick={onLanguageClick}/>
      <div className="actuality-container">
        {articlesSection}
      </div>
      <Footer language={language}/>
      <a onClick={scrollTop} className='global-top-btn' href="#" ><i className="fas fa-chevron-up"></i></a>
    </div>
  );
};

export default Actuality
